export enum PageNames {
  FINANCE_PAGE = 'invoices',
  INVOICE_DETAILS = 'invoiceDetails',
  PAYMENTS = 'payments',
  STATEMENTS = 'statements',
  ORDERS = 'orders',
  ORDER_DETAILS = 'orderDetails',
  TEST_REPORTS = 'testReports'
}

export enum ScreenSizeLabel {
  SMALL = 'S',
  MEDIUM = 'M',
  LARGE = 'L',
  UNDEFINED = 'Undefined'
}

export enum CustomerFeedbackProgrammeAction {
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
  DISMISS = 'dismiss'
}
